<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new score rule record -->
                <b-overlay :show="add_score_rule_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Add new score rule"
                        @ok="addScoreRuleRecord"
                    >
                        <b-form>
                            <b-form-group label="Continent" label-for="continentCode">
                                <v-select
                                    id="continentCode"
                                    label="text"
                                    :reduce="(text) => text.value"
                                    v-model="newGeoRule.continentCode"
                                    :options="ContinentOption"
                                    @input="continentInput"
                                />
                            </b-form-group>
                            <b-form-group label="Location" label-for="countryCode">
                                <v-select
                                    id="countryCode"
                                    label="text"
                                    :reduce="(text) => text.value"
                                    v-model="newGeoRule.countryCode"
                                    :options="CountryOption"
                                    @input="countryInput"
                                />
                            </b-form-group>

                            <b-form-group label="rate" label-for="rate">
                                <b-form-input id="rate" placeholder="rate ex. 0.9 0r 1.0, default 1.0" v-model="newGeoRule.rate" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Manager Score Geo Rule">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3">
                        <b-col>
                            <b-button class="mr-3" variant="primary" v-b-modal.modal-create @click="clickAddScoreRuleRecord"> Add Score Rule Record </b-button>

                            <VDropdown>
                                <b-button variant="secondary" class="mr-3 ml-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <b-form-input placeholder="record id" v-model="queryCondition.id" />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="continentCode"
                                                label="text"
                                                :reduce="(text) => text.value"
                                                placeholder="continent"
                                                v-model="queryCondition.continentCode"
                                                :options="ContinentOption"
                                            />
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-3">
                                        <b-col class="pl-0 mt-2 ml-3">
                                            <v-select
                                                id="countryCode"
                                                label="text"
                                                :reduce="(text) => text.value"
                                                placeholder="location"
                                                v-model="queryCondition.countryCode"
                                                :options="CountryOption"
                                            />
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'continent_code'">
                                    {{
                                        props.row["continent_code"] === ALL_Continent ? ALL_Continent : GetFullContinent(props.row["continent_code"])
                                    }}
                                </span>

                                <span v-else-if="props.column.field === 'country_code'">
                                    {{ props.row["country_code"] === ALL_Country ? ALL_Country : GetFullCountry(props.row["country_code"]) }}
                                </span>

                                <!-- <span v-else-if="props.column.field === 'rate'">
                                    {{ "$ " + (props.row.pdn_per_byte / 1000).toFixed(3) + " /GB" }}
                                </span> -->

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button variant="primary" @click="open_row_view_edit(props.row.originalIndex)">Edit/Delete</b-button>
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal id="modal_row_view_update" @hide="handleHide" title="view/edit" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="id" label-for="id">
                                    <b-form-input id="id" placeholder="id" v-model="this.focus.id" disabled />
                                </b-form-group>

                                <b-form-group label="Continent" label-for="continentCode">
                                    <v-select
                                        id="continentCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newGeoRule.continentCode"
                                        :options="ContinentOption"
                                        @input="continentInput"
                                        disabled
                                    />
                                </b-form-group>
                                <b-form-group label="Location" label-for="countryCode">
                                    <v-select
                                        id="countryCode"
                                        label="text"
                                        :reduce="(text) => text.value"
                                        v-model="newGeoRule.countryCode"
                                        :options="CountryOption"
                                        @input="countryInput"
                                        disabled
                                    />
                                </b-form-group>

                                <b-form-group label="rate" label-for="rate">
                                    <b-form-input id="rate" placeholder="rate ex. 0.9 0r 1.0, default 1.0" v-model="newGeoRule.rate" />
                                </b-form-group>

                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deleteScoreRuleRecord">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updateScoreRule">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import {
    ALL_Continent,
    ALL_Country,
    CountryMap,
    ContinentMap,
    GetFullCountry,
    GetFullContinent,
    ContinentOption,
    CountryOption,
} from "../../location/location";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        VueGoodTable,
        vSelect,
    },

    methods: {
        GetFullCountry,
        GetFullContinent,
        continentInput(code) {
            if (code === null || code === "") {
                this.newGeoRule.continentCode = this.ALL_Continent;
                this.newGeoRule.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Continent) {
                this.newGeoRule.countryCode = this.ALL_Country;
                return;
            }
            if (this.newGeoRule.countryCode === this.ALL_Country) {
                return;
            }
            let currentCountryCode = this.newGeoRule.countryCode;
            let countryInfo = CountryMap[currentCountryCode];
            let currentContinentCode = code;
            if (countryInfo.ContinentCode !== currentContinentCode) {
                this.newGeoRule.countryCode = this.ALL_Country;
            }
        },
        countryInput(code) {
            if (code === null || code === "") {
                this.newGeoRule.countryCode = this.ALL_Country;
                return;
            }
            if (code === this.ALL_Country) {
                return;
            }
            let countryCodeInput = code;
            let countryInfo = CountryMap[countryCodeInput];
            let continentCode = countryInfo.ContinentCode;
            this.newGeoRule.continentCode = continentCode;
        },

        clickAddScoreRuleRecord() {
            this.newGeoRule = {
                continentCode: ALL_Continent,
                countryCode: ALL_Country,
                rate: 1,
            };
        },
        clearQueryCondition() {
            this.queryCondition = {
                id: null,
                continentCode: null,
                countryCode: null,
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.newGeoRule.continentCode =
                this.focus.continent_code === this.ALL_Continent ? this.ALL_Continent : this.focus.continent_code;
            this.newGeoRule.countryCode = this.focus.country_code === this.ALL_Country ? this.ALL_Country : this.focus.country_code;
            this.newGeoRule.rate = this.focus.rate
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            this.table_show_overlay = true;
            let id = this.queryCondition.id ? parseInt(this.queryCondition.id) : null;
            let continent = this.queryCondition.continentCode;
            let country = this.queryCondition.countryCode;

            let resp = await this.$api.scoreRule.queryScoreRule(
                id,
                continent,
                country,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.score_geo_rule_list;
            this.totalRows = resp.result.count;
        },

        async updateScoreRule() {
            let rate = parseFloat(this.newGeoRule.rate)

            let resp = await this.$api.scoreRule.updateScoreRule(this.focus.id, rate, this.$store.state.auth.my_web_token);
            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("score rule updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });
            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");

            this.newGeoRule = {
                continentCode: "",
                countryCode: "",
                rate: "",
            };
        },

        async deleteScoreRuleRecord() {
            let value = await this.$bvModal.msgBoxConfirm(`Please confirm that you want to delete score rule record [${this.focus.id}] ?`, {
                title: "Delete score rule Record",
                okVariant: "danger",
                okTitle: "Delete",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.scoreRule.deleteScoreRule(this.focus.id, this.$store.state.auth.my_web_token);
                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("score rule record deleted", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                this.refreshTable();
                this.$bvModal.hide("modal_row_view_update");
            }
        },

        async addScoreRuleRecord(bvModalEvt) {
            this.add_score_rule_show_overlay = true;

            let continent = this.newGeoRule.continentCode;
            let country = this.newGeoRule.countryCode;
            let rate = parseFloat(this.newGeoRule.rate);

            let resp = await this.$api.scoreRule.createScoreRule(continent, country, rate, this.$store.state.auth.my_web_token);
            this.add_score_rule_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("score rule record added", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.newGeoRule = {
                continentCode: "",
                countryCode: "",
                rate: "",
            };
            this.refreshTable();
        },
    },

    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            //for table
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Continent",
                    field: "continent_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Location",
                    field: "country_code",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Rate",
                    field: "rate",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_score_rule_show_overlay: false,

            queryCondition: {
                id: null,
                continentCode: null,
                countryCode: null,
            },

            newGeoRule: {
                continentCode: "",
                countryCode: "",
                rate: 1,
               
            },

            ALL_Continent: ALL_Continent,
            ALL_Country: ALL_Country,
            CountryMap: CountryMap,
            ContinentMap: ContinentMap,
            ContinentOption: ContinentOption,
            CountryOption: CountryOption,
        };
    },
};
</script>
